import * as React from "react";
import { useState } from "react";
import { useLocation } from "@reach/router";

interface DrawProps {
  className?: string;
  children: string;
  title: string;
  id?: string;
}

const Draw = ({ id, className, children, title }: DrawProps) => {
  const [open, setOpen] = useState(useLocation().hash == `#${id}` ? true : false);
  const url = useLocation().origin + useLocation().pathname + "#" + id;

  const toggle = (): void => {
    if (!open) {
      history.pushState({}, title, url);
    }
    setOpen(!open);
  };

  return (
    <section className={`relative ${className ?? ""}`}>
      <span id={id} className="absolute -top-28"></span>
      <h2 className="border-t-2 m-0 text-lg hover:text-mg-green">
        <button className="w-full text-left uppercase pr-6 block font-bold py-4" onClick={() => toggle()}>
          {title}
        </button>
        <span
          className={`absolute right-2 top-4 pointer-events-none font-bold font-lg transition ${
            open ? "rotate-180" : "rotate-0"
          }`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 53.53 27.79"
            className="w-3 h-2 inline-block"
          >
            <polyline
              style={{
                strokeLinecap: "round",
                strokeMiterlimit: 10,
                strokeWidth: "6px",
              }}
              className="stroke-current fill-transparent"
              points=".5 1.13 26.45 27.08 53.03 .5"
            />
          </svg>
        </span>
      </h2>
      <div className={`${open ? "block pb-4 pt-2" : "hidden"}`}>{children}</div>
    </section>
  );
};

export { Draw };
