import * as React from "react";
import { widdowJoiner } from "../utils/stringUtils";
import Link from "./Link";

interface buttonProps {
  className?: string;
  children?: React.ReactNode;
}

interface optionProps {
  link: string;
  title: string;
  description: string;
  secondaryStyle?: boolean;
  className?: string;
}

const GetInTouch = ({ className }: buttonProps) => {
  return (
    <Link to="/business-enquiries" className={`btn ${className ?? ""}`}>
      Get In Touch
    </Link>
  );
};

const Login = ({ className, children }: buttonProps) => {
  return (
    <Link to="/log-in" className={`btn ${className ?? ""}`}>
      {children ?? "Login"}
    </Link>
  );
};

const GetStarted = ({ className }: buttonProps) => {
  return (
    <Link to="https://admin.musicglue.store/log-in/new" className={`btn ${className ?? ""}`}>
      Get Started
    </Link>
  );
};

const BannerOption = ({ link, title, description, secondaryStyle, className }: optionProps) => {
  return (
    <Link
      to={link}
      className={`${
        secondaryStyle ? "btn-ghost" : "btn"
      } hover:bg-white hover:text-mg-purple md:mb-0 mb-8 no-underline grow hover:shadow-xl overflow-clip rounded-md flex flex-col ${
        className ?? ""
      }`}
    >
      <div className="basis-1/2 py-4">
        <h2 className="text-xl mb-4 mx-4">{title}</h2>
        <p className="text-sm mb-2 md:mb-1 font-normal">{widdowJoiner(description)}</p>
      </div>
    </Link>
  );
};

const LoginBanner = ({ link, title, description, secondaryStyle, className }: optionProps) => {
  return (
    <Link
      to={link}
      className={`${
        secondaryStyle ? "btn-ghost" : "btn"
      } hover:bg-white hover:text-mg-purple md:mb-0 mb-8 p-4 no-underline grow hover:shadow-xl overflow-clip rounded-md flex flex-col ${
        className ?? ""
      }`}
    >
      <div className="basis-1/2 py-4 min-h-24">
        <h2 className="text-xl mb-4 mx-4">{title}</h2>
        <p className="text-sm mb-2 md:mb-1 font-normal">{widdowJoiner(description)}</p>
      </div>

      <div className="btn px-4">Log in</div>
    </Link>
  );
};

export { GetInTouch, Login, GetStarted, BannerOption, LoginBanner };
