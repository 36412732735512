import React from 'react'
import { GetInTouch } from "./ActionButtons";
import FeatureSVG from './FeatureSVG';

const PriceManaged = () => {

  return (
    <div className="bg-mg-green flex w-full flex-col rounded-2xl border border-mg-green shadow-md">
        <div className="w-full flex flex-col gap-4 p-8 text-center md:text-left">
            <span className="text-xl font-bold">Managed Service</span>
            <div className="flex flex-1 grow flex-col items-center gap-2 md:hidden">
                <span className="text-center text-5xl font-bold">15%</span>
                <span className="text-center text-base">transactions fees</span>
            </div>
        </div>
        <div className="flex w-full px-8 pb-4">
            <span className="text-base font-bold">Everything in Advanced, plus:</span>
        </div>
        <div className="flex w-full px-8 pb-8">
            <div className="flex w-full flex-col md:flex-row">
            <div className="flex w-full grow flex-col md:basis-2/3 pb-8 md:pb-0 md:flex-row">
                <div className="flex flex-1 grow">
                <ul>
                    <li className="flex items-center gap-2 py-2 text-left text-sm">
                        <FeatureSVG />
                        International chart reporting
                    </li>
                    <li className="flex items-center gap-2 py-2 text-left text-sm text-black">
                        <FeatureSVG />
                        Multi-currency & gross pricing
                    </li>
                    <li className="flex items-center gap-2 py-2 text-left text-sm text-black">
                        <FeatureSVG />
                        Multiple variants
                    </li>
                    <li className="flex items-center gap-2 py-2 text-left text-sm text-black">
                        <FeatureSVG />
                        Integrations with Zendesk, Klaviyo & more
                    </li>
                </ul>
                </div>
                <div className="flex flex-1 grow items-start">
                <ul>
                    <li className="flex items-center gap-2 py-2 text-left text-sm">
                        <FeatureSVG />
                        Account management & consultancy
                    </li>
                    <li className="flex items-center gap-2 py-2 text-left text-sm text-black">
                        <FeatureSVG />
                        Customer support
                    </li>
                    <li className="flex items-center gap-2 py-2 text-left text-sm text-black">
                        <FeatureSVG />
                        Custom store design & build
                    </li>
                </ul>
                </div>
            </div>
            <div className="flex basis-1/3 grow flex-col items-center gap-3">
                <span className="hidden md:block text-center text-5xl font-bold">15%</span>
                <span className="hidden md:block text-center text-base">commission</span>
                <div className="py-2 md:px-4 text-base w-full flex flex-col items-center gap-2">
                    <GetInTouch className="py-2 px-4 text-base w-full bg-mg-purple text-mg-green hover:bg-mg-yellow hover:text-mg-purple" />
                    <a 
                        href="#table" 
                        title="Click to view all features"
                        className="text-mg-purple text-sm font-semibold decoration-mg-purple hover:decoration-mg-yellow"
                    >
                        View all features
                    </a>
                </div>
            </div>
            </div>
        </div>
        </div>
  )
}

export default PriceManaged