import * as React from "react";

interface SVGprops {
  className?: string;
}

const Clock = ({ className }: SVGprops) => (
  <svg
    className={`w-5 inline-block ${className}`}
    xmlns="https://www.w3.org/2000/svg"
    viewBox="0 0 359.5 359.5"
  >
    <path
      className="fill-current"
      d="M179.75,88.71a91,91,0,1,0,91,91A91.14,91.14,0,0,0,179.75,88.71Zm58,82.58-47.21,27.36a14.47,14.47,0,0,1-11.48,5.9h0a14.62,14.62,0,0,1-14.2-11.22,14.07,14.07,0,0,1-.41-7.45l-.69-75.18a14.48,14.48,0,1,1,29-.26l.49,53.21,30-17.42a14.48,14.48,0,0,1,14.53,25.06Z"
    />
    <path
      className="fill-current"
      d="M179.75,0A179.75,179.75,0,1,0,359.5,179.75,179.75,179.75,0,0,0,179.75,0Zm0,299.75a120,120,0,1,1,120-120A120.13,120.13,0,0,1,179.75,299.75Z"
    />
  </svg>
);

const Facebook = ({ className }: SVGprops) => (
  <svg
    className={`w-5 inline-block ${className}`}
    xmlns="https://www.w3.org/2000/svg"
    viewBox="0 0 100.2 100.2"
  >
    <path
      className="fill-current"
      d="M94.7 0H5.5C2.5 0 0 2.5 0 5.5v89.2c0 3.1 2.5 5.5 5.5 5.5h48V61.4h-13V46.3h13.1V35.1c0-12.9 7.9-20 19.5-20 5.5 0 10.3.4 11.7.6v13.5h-8c-6.3 0-7.5 3-7.5 7.4v9.7h15l-2 15.1h-13v38.8h25.5c3.1 0 5.5-2.5 5.5-5.5V5.5c-.1-3-2.5-5.5-5.6-5.5z"
    />
  </svg>
);

const Instagram = ({ className }: SVGprops) => (
  <svg
    className={`w-5 inline-block ${className}`}
    xmlns="https://www.w3.org/2000/svg"
    viewBox="0 0 111.6 111.6"
  >
    <path
      className="fill-current"
      d="M111.3 32.8c-.3-6-1.2-10-2.6-13.6-1.4-3.7-3.3-6.8-6.4-9.9s-6.2-5-9.9-6.4C88.8 1.5 84.8.6 78.8.3s-7.9-.3-23-.3c-15.2 0-17.1.1-23 .3a42 42 0 0 0-13.6 2.6c-3.7 1.4-6.8 3.3-9.9 6.4s-5 6.2-6.4 9.9C1.5 22.8.6 26.8.3 32.8s-.3 7.9-.3 23 .1 17.1.3 23a42 42 0 0 0 2.6 13.6c1.4 3.7 3.3 6.8 6.4 9.9s6.2 5 9.9 6.4c3.6 1.4 7.6 2.3 13.6 2.6 6 .3 7.9.3 23 .3 15.2 0 17.1-.1 23-.3a42 42 0 0 0 13.6-2.6c3.7-1.4 6.8-3.3 9.9-6.4s5-6.2 6.4-9.9c1.4-3.6 2.3-7.6 2.6-13.6.3-6 .3-7.9.3-23s0-17-.3-23zm-10 45.7c-.2 5.5-1.2 8.4-1.9 10.4-1 2.6-2.2 4.5-4.2 6.4a17 17 0 0 1-6.4 4.2c-2 .8-4.9 1.7-10.4 1.9-5.9.3-7.7.3-22.6.3s-16.7-.1-22.6-.3c-5.5-.2-8.4-1.2-10.4-1.9-2.6-1-4.5-2.2-6.4-4.2a17 17 0 0 1-4.2-6.4c-.8-2-1.7-4.9-1.9-10.4-.3-5.9-.3-7.7-.3-22.6s.1-16.7.3-22.6c.2-5.4 1.2-8.4 1.9-10.4 1-2.6 2.2-4.5 4.2-6.4s3.8-3.2 6.4-4.2c2-.8 4.9-1.7 10.4-1.9 5.9-.3 7.7-.3 22.6-.3s16.7.1 22.6.3c5.5.2 8.4 1.2 10.4 1.9 2.6 1 4.5 2.2 6.4 4.2 2 2 3.2 3.8 4.2 6.4.8 2 1.7 4.9 1.9 10.4.3 5.9.3 7.7.3 22.6s-.1 16.7-.3 22.6z"
    />
    <path
      className="fill-current"
      d="M55.8 27.2a28.6 28.6 0 1 0 0 57.4 28.7 28.7 0 0 0 0-57.4zm0 47.3a18.6 18.6 0 1 1 18.5-18.6 18.5 18.5 0 0 1-18.5 18.6z"
    />
    <circle className="fill-current" cx="85.6" cy="26" r="6.7" />
  </svg>
);

const Linkedin = ({ className }: SVGprops) => (
  <svg
    className={`w-5 inline-block ${className}`}
    xmlns="https://www.w3.org/2000/svg"
    viewBox="0 0 144 144"
  >
    <path
      className="fill-current"
      d="M133.4 0H10.6C4.8 0 0 4.8 0 10.6v122.7c0 5.9 4.8 10.6 10.6 10.6h122.7c5.9 0 10.6-4.8 10.6-10.6V10.6C144 4.8 139.2 0 133.4 0zM42.9 122.7H21.3V53.9h21.6v68.8zM32.1 44.4c-6.9 0-12.5-5.2-12.5-12.4s5.6-12.4 12.5-12.4S44.6 24.9 44.6 32 39 44.4 32.1 44.4zm90.6 78.3h-21.6V85.2c0-11.1-4.7-14.5-10.8-14.5-6.4 0-12.7 4.8-12.7 14.8v37.3H55.9V54h20.8v9.5h.3A23.5 23.5 0 0 1 97.6 52c12.1 0 25.1 7.2 25.1 28.2v42.5z"
    />
  </svg>
);

const Spotify = ({ className }: SVGprops) => (
  <svg
    className={`w-5 inline-block ${className}`}
    xmlns="https://www.w3.org/2000/svg"
    viewBox="0 0 144.5 144.5"
  >
    <path
      className="fill-current"
      d="M144.5 72.3A72.2 72.2 0 1 1 73.4 0a72.4 72.4 0 0 1 71.1 72.3zM61.7 37.8v.3A109.3 109.3 0 0 0 27 42.7a6.6 6.6 0 0 0-4.5 8.4 6.8 6.8 0 0 0 8.6 4.7 108.8 108.8 0 0 1 39.5-3.6c4.8.4 9.6.9 14.4 1.7 10.7 1.7 21 4.9 30.4 10.5 1.5.9 3.3 1 4.9.6 3.2-.8 4.9-3.1 5.1-6.3.2-2.7-1-4.9-3.4-6.3-2.1-1.2-4.2-2.4-6.4-3.4a114 114 0 0 0-37.1-9.9c-5.6-.7-11.2-.9-16.8-1.3zM60.2 63a87 87 0 0 0-29 4.1 5.6 5.6 0 0 0-4.2 5.2c-.1 4.1 3.6 7 7.7 5.8a96.8 96.8 0 0 1 71.8 8.5c1 .6 2.2.9 3.3.9 2.6 0 4.7-1.9 5.3-4.3.6-2.5-.4-5-2.7-6.4-2.5-1.4-5-2.9-7.6-4.1A102.7 102.7 0 0 0 60.2 63zm.8 23.4c-4.5.4-9.1.5-13.6 1.1-4.7.6-9.3 1.5-13.9 2.5-2.5.5-3.9 3.1-3.3 5.6a4.6 4.6 0 0 0 5.5 3.3c6.6-1.5 13.3-2.6 20.1-2.9 5.9-.3 11.7-.1 17.6.7a69.9 69.9 0 0 1 25.8 9.1c2.3 1.4 5.1.7 6.4-1.5 1.4-2.2.7-5.1-1.6-6.4-2.9-1.6-5.8-3.3-8.9-4.6A85.2 85.2 0 0 0 61 86.4z"
      fill-rule="evenodd"
      clip-rule="evenodd"
    />
  </svg>
);

const Tshirt = ({ className }: SVGprops) => (
  <svg
    className={`w-5 inline-block ${className}`}
    xmlns="https://www.w3.org/2000/svg"
    viewBox="0 0 228.17 209.21"
  >
    <path
      className="fill-current"
      d="M0,31.81V27.93C24.91,19.87,61.9,6.46,87.2.12c2.65-.67,4.37,1.08,10.55,12.75,7.62,14.55,29.2,14.22,35.6-.32C138.42,1.06,144.56.07,144.56.07c25,7.52,58.7,19.8,83.61,27.86v3.88c-4.53,15-9.08,30.12-13.72,45.57-11.93-3.67-23.2-7.11-35.67-10.94V209.21H51V66.61c-12.85,3.76-24.67,7.23-36.36,10.67L0,31.81"
    />
  </svg>
);

const Twitter = ({ className }: SVGprops) => (
  <svg
    className={`w-5 inline-block ${className}`}
    xmlns="https://www.w3.org/2000/svg"
    viewBox="0 0 26.2 21.3"
  >
    <path
      className="fill-current"
      d="M26.2 2.5c-1 .4-2 .7-3.1.8 1.1-.7 2-1.7 2.4-3a10 10 0 0 1-3.4 1.3 5.4 5.4 0 0 0-9.3 3.7l.1 1.2A15 15 0 0 1 1.8 1a6 6 0 0 0-.7 2.7c0 1.9.9 3.5 2.4 4.5-.9 0-1.7-.3-2.4-.7v.1c0 2.6 1.9 4.8 4.3 5.3l-1.5.1-1-.1a5.4 5.4 0 0 0 5 3.7A10.3 10.3 0 0 1 1.3 19L0 18.9A15.2 15.2 0 0 0 23.5 6v-.7c1.1-.8 2-1.7 2.7-2.8z"
    />
  </svg>
);

const Vinyl = ({ className }: SVGprops) => (
  <svg
    className={`w-5 inline-block ${className}`}
    xmlns="https://www.w3.org/2000/svg"
    viewBox="0 0 620 620"
  >
    <path
      className="fill-current"
      d="M310,0C138.8,0,0,138.8,0,310,0,438.4,78.1,548.6,189.3,595.6A309,309,0,0,0,310,620c171.2,0,310-138.8,310-310S481.2,0,310,0ZM88.3,245A16.48,16.48,0,0,1,73,255.6a15.41,15.41,0,0,1-5.9-1.1,16.6,16.6,0,0,1-9.4-21.3c2.1-5.5,53.1-134.9,192.6-170.8a16.42,16.42,0,0,1,8.2,31.8C135.2,125.9,88.8,243.8,88.3,245Zm78.5,12.1a16.38,16.38,0,0,1-15.3,10.5,17.57,17.57,0,0,1-5.9-1.1,16.33,16.33,0,0,1-9.4-21.1c1.3-3.5,33.5-85,121.3-107.6a16.42,16.42,0,0,1,8.2,31.8C194.5,187.8,167,256.4,166.8,257.1ZM210,310A100,100,0,1,1,310,410,100,100,0,0,1,210,310ZM342.9,477.1a16.39,16.39,0,0,1,11.8-20c71.1-18.2,98.6-86.9,98.9-87.6a16.4,16.4,0,0,1,30.6,11.8c-1.3,3.5-33.5,85-121.3,107.6a14.4,14.4,0,0,1-4.1.5A16.29,16.29,0,0,1,342.9,477.1Zm27.2,87.1a14.83,14.83,0,0,1-4.1.5,16.41,16.41,0,0,1-4.1-32.3c122.8-31.5,169.8-149.6,170.2-150.7a16.4,16.4,0,0,1,30.6,11.8C560.6,398.9,509.6,528.4,370.1,564.2Z"
    />
    <circle className="fill-current" cx="310" cy="310" r="11" />
  </svg>
);

const Youtube = ({ className }: SVGprops) => (
  <svg
    className={`w-5 inline-block ${className}`}
    xmlns="https://www.w3.org/2000/svg"
    viewBox="0 0 30.2 21.2"
  >
    <path
      className="fill-current"
      d="M29.5 3.3A3.9 3.9 0 0 0 26.8.6C24.5 0 15 0 15 0S5.6 0 3.3.6A4 4 0 0 0 .6 3.3C0 5.7 0 10.6 0 10.6s0 4.9.6 7.3a3.9 3.9 0 0 0 2.7 2.7c2.3.6 11.8.6 11.8.6s9.4 0 11.8-.6a3.9 3.9 0 0 0 2.7-2.7c.6-2.4.6-7.3.6-7.3s-.1-4.9-.7-7.3zM12 15.1v-9l7.9 4.5-7.9 4.5z"
    />
  </svg>
);

export { Clock, Facebook, Instagram, Linkedin, Spotify, Tshirt, Twitter, Vinyl, Youtube };
