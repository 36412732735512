import React from 'react'
import { GetStarted } from "./ActionButtons";
import FeatureSVG from './FeatureSVG';

export interface planProps {
    plan: string,
    price: number,
    rate: number,
    tagline: string,
    features: string[],
}


const PricePlan = ( {plan, price, rate, tagline, features }: planProps ) => {
  return (
    <div className="flex w-full max-w-96 flex-col overflow-hidden rounded-2xl border border-gray-100 shadow-md">
        <div className="flex w-full flex-col items-center gap-3 p-8">
            <span className="text-xl font-bold text-center">{plan} plan</span>
            <div className="flex flex-col gap-2">
            <span className="text-5xl md:text-4xl lg:text-5xl font-bold text-center">£{price}<span className="text-xl">/mo</span></span>
            <span className="text-base md:text-sm lg:text-base text-center">+{rate}% commission</span>
            </div>
        </div>

        <div className="flex grow w-full flex-col px-8 pb-8 gap-4">
            <span className="text-base font-bold text-left">{tagline}:</span>
            <ul>
                {features && features.map((feature, index) => (
                    <li key={index} className="flex items-center gap-2 py-2 text-left text-sm">
                        <FeatureSVG />
                        {feature}
                    </li>
                ))}
            </ul>
        </div>

        <div className="flex w-full flex-col items-center justify-center bg-gray-100 p-8 gap-2">
            <GetStarted className="py-2 px-4 text-base w-full hover:bg-mg-yellow hover:text-mg-purple" />
            <a 
                href="#table" 
                title="Click to view all features"
                className="text-mg-purple text-sm font-semibold"
            >
                View all features
            </a>
        </div>
    </div>

  )
}

export default PricePlan