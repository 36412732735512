import { useStaticQuery, graphql } from "gatsby";
import * as React from "react";
import { GatsbyLinkProps, Link as GatsbyLink } from "gatsby";
type Omit<T, K> = Pick<T, Exclude<keyof T, K>>;

// Strip assetPrefix from relative links, see:
// https://github.com/gatsbyjs/gatsby/issues/21462#issuecomment-605606702
interface SiteConfig {
  __prefix?: string;
  readonly prefix: string;
}

const site: SiteConfig = {
  get prefix() {
    if (this.__prefix == undefined) {
      const { site } = useStaticQuery(
        graphql`
          {
            site {
              assetPrefix
            }
          }
        `,
      );

      Object.defineProperty(this, "__prefix", {
        configurable: false,
        enumerable: false,
        writable: false,
        value: site.assetPrefix.replace("https://", "https:/"),
      });
    }

    if (this.__prefix == undefined) {
      throw new Error("Expected assetPrefix to be set");
    }

    return this.__prefix;
  },
};

const useStripAssetPrefix = (href: string): string => {
  const assetPrefix = site.prefix;
  if (href.length < assetPrefix.length || !href.startsWith(assetPrefix)) {
    return href;
  }
  return href.slice(assetPrefix.length);
};

const Link: React.FC<Omit<GatsbyLinkProps<{}>, "ref">> = ({ children, to: href, ...other }) => {
  const to = useStripAssetPrefix(href);
  const internal = /^\/(?!\/)/.test(to);
  if (internal) {
    return (
      <GatsbyLink to={to} {...other}>
        {children}
      </GatsbyLink>
    );
  }
  return (
    <a href={to} rel="noopener" {...other}>
      {children}
    </a>
  );
};

export default Link;
