import React from "react";

interface Label extends React.LabelHTMLAttributes<HTMLLabelElement> {
  required?: boolean;
}

const Input = ({ className, ...attributes }: React.InputHTMLAttributes<HTMLInputElement>) => {
  return <input className={`w-full ${className}`} {...attributes} />;
};

const TextArea = ({
  className,
  ...attributes
}: React.TextareaHTMLAttributes<HTMLTextAreaElement>) => {
  return <textarea className={`input w-full ${className ?? ""}`} {...attributes}></textarea>;
};

const Label = ({ children, className, required, ...attributes }: Label) => {
  return (
    <label className={`mb-2 block ${className ?? ""}`} {...attributes}>
      {children}
      {required && <span className="text-xs font-normal float-right opacity-70">*required</span>}
    </label>
  );
};

const Select = ({
  children,
  className,
  ...attributes
}: React.SelectHTMLAttributes<HTMLSelectElement>) => {
  return (
    <div className="relative mb-6 w-full">
      <select
        className={`shadow-inner appearance-none border border-gray-300 rounded w-full block bg-white focus:border-gray-600 px-4 py-2 pr-8 text-gray-700 focus:outline-none focus:shadow-outline ${className}`}
        {...attributes}
      >
        {children}
      </select>
      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
        <svg
          className="fill-current h-4 w-4"
          xmlns="https://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
        </svg>
      </div>
    </div>
  );
};

const EnquiryForm = () => {
  return (
    <form action="https://formspree.io/mzyrzddx" method="POST">
      <div className="grid grid-cols-1 gap-x-6 sm:grid-cols-2">
        <div>
          <Label htmlFor="name" required>
            Your Name
          </Label>
          <Input type="text" id="name" name="name" required />
        </div>
        <div>
          <Label htmlFor="emailAddress" required>
            Email Address
          </Label>
          <Input type="email" id="emailAddress" name="_replyto" required />
        </div>
        <div>
          <Label htmlFor="businessName">Business Name</Label>
          <Input type="text" id="businessName" name="business-name" />
        </div>
        <div>
          <Label htmlFor="businessType">Business type</Label>
          <Select id="businessType" name="business-type" required>
            <option value="#" disabled selected></option>
            <option value="band/artist (self-service requested)">
              Band or Artist requiring self-service account
            </option>
            <option value="band/artist (managed account requested)">
              Band or Artist requiring account management by Music Glue
            </option>
            <option value="managment co">Management Company</option>
            <option value="merch co">Merchandise Company</option>
            <option value="record label or label services">Record Label or Label Services</option>
            <option value="booking agent">Booking Agent</option>
            <option value="promoter/venue">Promoter or Venue</option>
            <option value="other">Other</option>
          </Select>
        </div>
      </div>
      <Label htmlFor="phone">Phone (if you prefer a call)</Label>
      <Input id="phone" type="tel" name="phone" />

      <Label htmlFor="message">How can we help?</Label>
      <TextArea name="message" rows={8} />

      <Input
        type="hidden"
        name="_next"
        value="https://www.musicglue.com/thanks-for-getting-in-touch"
      />

      <button type="submit" className="w-36">
        Send
      </button>
    </form>
  );
};

export default EnquiryForm;
