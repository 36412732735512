import React from 'react'
import FeatureSVG from './FeatureSVG';
import { GetStarted, GetInTouch } from "./ActionButtons";

const ComparisonTable = () => {
  return (
    <div className="flex w-full flex-col md:flex-row">
        {/* Features column */}
        <section className="hidden md:flex md:flex-col md:flex-initial md:w-64">
            <div className="invisible text-lg lg:text-xl h-8 font-bold md:text-center">Features</div>
            <div className="py-2 font-bold">Products</div>
            <div className="flex flex-col w-full gap-2 rounded p-3 text-base h-24 md:rounded-tr-none md:rounded-br-none bg-gray-100">
                <div className="text-base">Unlimited products</div>
                <div className="text-sm opacity-70 text-left">Setup and sell as many products as you desire</div>
            </div>
            <div className="flex flex-col w-full gap-2 rounded p-3 text-base h-24 md:rounded-tr-none md:rounded-br-none">
                <div className="text-base">Schedule product launches</div>
                <div className="text-sm opacity-70 text-left">Prepare your campaign in advance and let it begin automatically</div>
            </div>
            <div className="flex flex-col w-full gap-2 rounded p-3 text-base h-24 md:rounded-tr-none md:rounded-br-none bg-gray-100">
                <div className="text-base">Geographical restrictions</div>
                <div className="text-sm opacity-70 text-left">Limit which customers can place orders</div>
            </div>
            <div className="flex flex-col w-full gap-2 rounded p-3 text-base h-20 md:rounded-tr-none md:rounded-br-none">
                <div className="text-base">Pre-orders</div>
                <div className="text-sm opacity-70 text-left">Sell products now; fulfil later</div>
            </div>
            <div className="flex flex-col w-full gap-2 rounded p-3 text-base h-24 md:rounded-tr-none md:rounded-br-none bg-gray-100">
                <div className="text-base">Multiple variants</div>
                <div className="text-sm opacity-70 text-left">Create products with, for example, size <i>and</i> colour options</div>
            </div>
            <div className="flex flex-col w-full gap-2 rounded p-3 text-base h-20 md:rounded-tr-none md:rounded-br-none">
                <div className="text-base">Flexibile pricing setup</div>
                <div className="text-sm opacity-70 text-left">Handle tax globally with ease</div>
            </div>
            <div className="flex flex-col w-full gap-2 rounded p-3 text-base h-24 md:rounded-tr-none md:rounded-br-none bg-gray-100">
                <div className="text-base">Multi-currency</div>
                <div className="text-sm opacity-70 text-left">Allow customers to purchase in their local currency</div>
            </div>

            <div className="invisible py-1 md:py-0">space block</div>
            <div className="py-2 font-bold">Campaigns</div>
            <div className="flex flex-col w-full gap-2 rounded p-3 text-base h-24 md:rounded-tr-none md:rounded-br-none bg-gray-100">
                <div className="text-base">Bundles</div>
                <div className="text-sm opacity-70 text-left">Sell more via bundling products together at a discounted price</div>
            </div>
            <div className="flex flex-col w-full gap-2 rounded p-3 text-base h-24 md:rounded-tr-none md:rounded-br-none">
                <div className="text-base">Voucher codes</div>
                <div className="text-sm opacity-70 text-left">Reward and incentivise your customers</div>
            </div>
            <div className="flex flex-col w-full gap-2 rounded p-3 text-base h-24 md:rounded-tr-none md:rounded-br-none bg-gray-100">
                <div className="text-base">Fan clubs</div>
                <div className="text-sm opacity-70 text-left">Build a community and generate a recurring revenue stream</div>
            </div>
            <div className="flex flex-col w-full gap-2 rounded p-3 text-base h-24 md:rounded-tr-none md:rounded-br-none">
                <div className="text-base">YouTube Merch shelf</div>
                <div className="text-sm opacity-70 text-left">Advertise your products on YouTube</div>
            </div>
            <div className="flex flex-col w-full gap-2 rounded p-3 text-base h-24 md:rounded-tr-none md:rounded-br-none bg-gray-100">
                <div className="text-base">UK chart reporting</div>
                <div className="text-sm opacity-70 text-left">Your sales automatically reported to the Official Charts Company</div>
            </div>
            <div className="flex flex-col w-full gap-2 rounded p-3 text-base h-24 md:rounded-tr-none md:rounded-br-none">
                <div className="text-base">International chart reporting</div>
                <div className="text-sm opacity-70 text-left">Automated reporting to 10+ charts globally</div>
            </div>

            <div className="invisible py-1 md:py-0">space block</div>
            <div className="py-2 font-bold">General</div>
            <div className="flex flex-col w-full gap-2 rounded p-3 text-base h-24 md:rounded-tr-none md:rounded-br-none bg-gray-100">
                <div className="text-base">Manage your orders</div>
                <div className="text-sm opacity-70 text-left">Including fulfilment and customer support tools</div>
            </div>
            <div className="flex flex-col w-full gap-2 rounded p-3 text-base h-24 md:rounded-tr-none md:rounded-br-none">
                <div className="text-base">Design your store</div>
                <div className="text-sm opacity-70 text-left">A powerful yet simple store designer</div>
            </div>
            <div className="flex flex-col w-full gap-2 rounded p-3 text-base h-20 md:rounded-tr-none md:rounded-br-none bg-gray-100">
                <div className="text-base">Analytics dashboard</div>
                <div className="text-sm opacity-70 text-left">Visualise your sales in real-time</div>
            </div>
            <div className="flex flex-col w-full gap-2 rounded p-3 text-base h-24 md:rounded-tr-none md:rounded-br-none">
                <div className="text-base">Multiple users</div>
                <div className="text-sm opacity-70 text-left">Allow your team to collaborate on your store</div>
            </div>
            <div className="flex flex-col w-full gap-2 rounded p-3 text-base h-24 md:rounded-tr-none md:rounded-br-none bg-gray-100">
                <div className="text-base">User permissions</div>
                <div className="text-sm opacity-70 text-left">Define exactly what collaborators are permitted to do</div>
            </div>
            <div className="flex flex-col w-full gap-2 rounded p-3 text-base h-24 md:rounded-tr-none md:rounded-br-none">
                <div className="text-base">Custom domains</div>
                <div className="text-sm opacity-70 text-left">Further promote your brand with a domain name for your store</div>
            </div>
            <div className="flex flex-col w-full gap-2 rounded p-3 text-base h-24 md:rounded-tr-none md:rounded-br-none bg-gray-100">
                <div className="text-base">Multiple fulfillers</div>
                <div className="text-sm opacity-70 text-left">Ship your orders from multiple warehouses</div>
            </div>
            <div className="flex flex-col w-full gap-2 rounded p-3 text-base h-24 md:rounded-tr-none md:rounded-br-none">
                <div className="text-base">Advanced SEO</div>
                <div className="text-sm opacity-70 text-left">Customise data used for search engine optimisation</div>
            </div>
            <div className="flex flex-col w-full gap-2 rounded p-3 text-base h-24 md:rounded-tr-none md:rounded-br-none bg-gray-100">
                <div className="text-base">Customer support</div>
                <div className="text-sm opacity-70 text-left">Our dedicated customer service team look after your fans 24/7</div>
            </div>
            <div className="flex flex-col w-full gap-2 rounded p-3 text-base h-24 md:rounded-tr-none md:rounded-br-none">
                <div className="text-base">Custom store design & build</div>
                <div className="text-sm opacity-70 text-left">Our in-house design team will build your storefront</div>
            </div>
            <div className="flex flex-col w-full gap-2 rounded p-3 text-base h-24 md:rounded-tr-none md:rounded-br-none bg-gray-100">
                <div className="text-base">Account management</div>
                <div className="text-sm opacity-70 text-left">Our account experts will manage your store</div>
            </div>
            <div className="flex flex-col w-full gap-2 rounded p-3 text-base h-28 md:rounded-tr-none md:rounded-br-none">
                <div className="text-base">Consultancy</div>
                <div className="text-sm opacity-70 text-left">Our e-commerce consultants will help you get the most from your store</div>
            </div>


            <div className="invisible py-1 md:py-0">space block</div>
            <div className="py-2 font-bold">Integrations</div>
            <div className="flex flex-col w-full gap-2 rounded p-3 text-base h-24 md:rounded-tr-none md:rounded-br-none bg-gray-100">
                <div className="text-base">Global Fulfilment</div>
                <div className="text-sm opacity-70 text-left">Integrate with warehouses worldwide</div>
            </div>
            <div className="flex flex-col w-full gap-2 rounded p-3 text-base h-20 md:rounded-tr-none md:rounded-br-none">
                <div className="text-base">Meta</div>
                <div className="text-sm opacity-70 text-left">Facebook and Instagram shopping</div>
            </div>
            <div className="flex flex-col w-full gap-2 rounded p-3 text-base h-24 md:rounded-tr-none md:rounded-br-none bg-gray-100">
                <div className="text-base">Klaviyo</div>
                <div className="text-sm opacity-70 text-left">Marketing and e-commerce combined</div>
            </div>
            <div className="flex flex-col w-full gap-2 rounded p-3 text-base h-20 md:rounded-tr-none md:rounded-br-none">
                <div className="text-base">Zendesk</div>
                <div className="text-sm opacity-70 text-left">Customer support at scale</div>
            </div>
            <div className="flex flex-col w-full gap-2 rounded p-3 text-base h-24 md:rounded-tr-none md:rounded-br-none bg-gray-100">
                <div className="text-base">Google Products & Services</div>
                <div className="text-sm opacity-70 text-left">Analytics, Tag Manager, Merchant Centre and more</div>
            </div>
            <div className="flex flex-col w-full gap-2 rounded p-3 text-base h-24 md:rounded-tr-none md:rounded-br-none">
                <div className="text-base">Mailchimp</div>
                <div className="text-sm opacity-70 text-left">Sync your audience and utilise e-commerce marketing</div>
            </div>
            <div className="flex flex-col w-full gap-2 rounded p-3 text-base h-12 md:rounded-tr-none md:rounded-br-none bg-gray-100">
                <div className="text-base"><i>Plus many more integrations...</i></div>
            </div>
        </section>

        {/* Lite Plan */}
        <section className="flex flex-col md:flex-1 mb-16 md:mb-0">
            <div className="text-lg lg:text-xl h-8 font-bold md:text-center">Lite</div>
            <div className="py-2 font-bold md:invisible">Products</div>
            <div className="flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-22 md:h-24 bg-gray-100">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Unlimited products
                    <div className="text-sm opacity-70 text-left md:hidden">Setup and sell as many products as you desire</div>
                </div>
                <div className="flex items-start justify-center order-1 pt-1 md:pt-0 md:items-center md:order-2"><FeatureSVG /></div>
            </div>
            <div className="hidden md:flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-22 md:h-24">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Schedule product launches
                    <div className="text-sm opacity-70 text-left md:hidden">Prepare your campaign in advance and let it begin automatically</div>
                </div>
            </div>
            <div className="hidden md:flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-20 md:h-24 bg-gray-100">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Geographical restrictions
                    <div className="text-sm opacity-70 text-left md:hidden">Limit which customers can place orders</div>
                </div>
            </div>
            <div className="hidden md:flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-20 md:h-22">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Pre-orders
                    <div className="text-sm opacity-70 text-left md:hidden">Sell products now; fulfil later</div>
                </div>
            </div>
            <div className="hidden md:flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-22 md:h-24 bg-gray-100">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Multiple variants
                    <div className="text-sm opacity-70 text-left md:hidden">Create products with, for example, size <i>and</i> colour options</div>
                </div>
            </div>
            <div className="hidden md:flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-20 md:h-22">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Flexibile pricing setup
                    <div className="text-sm opacity-70 text-left md:hidden">Handle tax globally with ease</div>
                </div>
            </div>
            <div className="hidden md:flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-22 md:h-24 bg-gray-100">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Multi-currency
                    <div className="text-sm opacity-70 text-left md:hidden">Allow customers to purchase in their local currency</div>
                </div>
            </div>
            
            <div className="hidden md:flex md:invisible py-1 md:py-0">space block</div>
            <div className="hidden md:flex py-2 font-bold md:invisible">Campaigns</div>
            <div className="hidden md:flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-22 md:h-24 bg-gray-100">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Bundles
                    <div className="text-sm opacity-70 text-left md:hidden">Sell more via bundling products together at a discounted price</div>
                </div>
            </div>
            <div className="hidden md:flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-22 md:h-24">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Voucher codes
                    <div className="text-sm opacity-70 text-left md:hidden">Reward and incentivise your customers</div>
                </div>
            </div>
            <div className="hidden md:flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-22 md:h-24 bg-gray-100">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Fan clubs
                    <div className="text-sm opacity-70 text-left md:hidden">Build a community and generate a recurring revenue stream</div>
                </div>
            </div>
            <div className="hidden md:flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-22 md:h-24">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    YouTube Merch shelf
                    <div className="text-sm opacity-70 text-left md:hidden">Advertise your products on YouTube</div>
                </div>
            </div>
            <div className="hidden md:flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-22 md:h-24 bg-gray-100">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    UK chart reporting
                    <div className="text-sm opacity-70 text-left md:hidden">Your sales automatically reported to the Official Charts Company</div>
                </div>
            </div>
            <div className="hidden md:flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-22 md:h-24">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    International chart reporting
                    <div className="text-sm opacity-70 text-left md:hidden">Automated reporting to 10+ charts globally</div>
                </div>
            </div>

            <div className="invisible py-1 md:py-0">space block</div>
            <div className="flex py-2 font-bold md:invisible">General</div>
            <div className="flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-22 md:h-24 bg-gray-100">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Manage your orders
                    <div className="text-sm opacity-70 text-left md:hidden">Including fulfilment and customer support tools</div>
                </div>
                <div className="flex items-start justify-center order-1 pt-1 md:pt-0 md:items-center md:order-2"><FeatureSVG /></div>
            </div>
            <div className="flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-22 md:h-24">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Design your store
                    <div className="text-sm opacity-70 text-left md:hidden">A powerful yet simple store designer</div>
                </div>
                <div className="flex items-start justify-center order-1 pt-1 md:pt-0 md:items-center md:order-2"><FeatureSVG /></div>
            </div>
            <div className="hidden md:flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-20 md:h-20 bg-gray-100">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Analytics dashboard
                    <div className="text-sm opacity-70 text-left md:hidden">Visualise your sales in real-time</div>
                </div>
            </div>
            <div className="hidden md:flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-22 md:h-24">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Multiple users
                    <div className="text-sm opacity-70 text-left md:hidden">Allow your team to collaborate on your store</div>
                </div>
            </div>
            <div className="hidden md:flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-22 md:h-24 bg-gray-100">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    User permissions
                    <div className="text-sm opacity-70 text-left md:hidden">Define exactly what collaborators are permitted to do</div>
                </div>
            </div>
            <div className="hidden md:flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-22 md:h-24">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Custom domains
                    <div className="text-sm opacity-70 text-left md:hidden">Further promote your brand with a domain name for your store</div>
                </div>
            </div>
            <div className="hidden md:flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-22 md:h-24 bg-gray-100">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Multiple fulfillers
                    <div className="text-sm opacity-70 text-left md:hidden">Ship your orders from multiple warehouses</div>
                </div>
            </div>
            <div className="hidden md:flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-22 md:h-24">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Advanced SEO
                    <div className="text-sm opacity-70 text-left md:hidden">Customise data used for search engine optimisation</div>
                </div>
            </div>
            <div className="hidden md:flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-22 md:h-24 bg-gray-100">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Customer support
                    <div className="text-sm opacity-70 text-left md:hidden">Our dedicated customer service team look after your fans 24/7</div>
                </div>
            </div>
            <div className="hidden md:flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-22 md:h-24">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Custom store design & build
                    <div className="text-sm opacity-70 text-left md:hidden">Our in-house design team will build your storefront</div>
                </div>
            </div>
            <div className="hidden md:flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-22 md:h-24 bg-gray-100">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Account management
                    <div className="text-sm opacity-70 text-left md:hidden">Our account experts will manage your store</div>
                </div>
            </div>
            <div className="hidden md:flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-24 md:h-28">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Consultancy
                    <div className="text-sm opacity-70 text-left md:hidden">Our e-commerce consultants will help you get the most from your store</div>
                </div>
            </div>


            <div className="hidden md:flex md:invisible py-1 md:py-0">space block</div>
            <div className="hidden md:flex py-2 font-bold md:invisible">Integrations</div>
            <div className="hidden md:flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-22 md:h-24 bg-gray-100">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Global Fulfilment
                    <div className="text-sm opacity-70 text-left md:hidden">Integrate with warehouses worldwide</div>
                </div>
            </div>
            <div className="hidden md:flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-22 md:h-20">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Meta
                    <div className="text-sm opacity-70 text-left md:hidden">Facebook and Instagram shopping</div>
                </div>
            </div>
            <div className="hidden md:flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-22 md:h-24 bg-gray-100">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Klaviyo
                    <div className="text-sm opacity-70 text-left md:hidden">Marketing and e-commerce combined</div>
                </div>
            </div>
            <div className="hidden md:flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-22 md:h-20">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Zendesk
                    <div className="text-sm opacity-70 text-left md:hidden">Customer support at scale</div>
                </div>
            </div>
            <div className="hidden md:flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-22 md:h-24 bg-gray-100">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Google Products & Services
                    <div className="text-sm opacity-70 text-left md:hidden">Analytics, Tag Manager, Merchant Centre and more</div>
                </div>
            </div>
            <div className="hidden md:flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-22 md:h-24">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Mailchimp
                    <div className="text-sm opacity-70 text-left md:hidden">Sync your audience and utilise e-commerce marketing</div>
                </div>
            </div>
            <div className="hidden md:flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-r h-12 md:h-12 bg-gray-100">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    <i>Plus many more integrations...</i>
                </div>
            </div>
            <div className="flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none mt-4">
                <GetStarted className="py-2 px-4 text-base md:text-xs lg:text-base w-full hover:bg-mg-yellow hover:text-mg-purple"/>
            </div>
        </section>

         {/* Standard Plan */}
         <section className="flex flex-col md:flex-1 mb-16 md:mb-0">
            <div className="text-lg lg:text-xl h-8 font-bold md:text-center">Standard</div>
            <div className="py-2 font-bold md:invisible">Products</div>
            <div className="flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-22 md:h-24 bg-gray-100">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Unlimited products
                    <div className="text-sm opacity-70 text-left md:hidden">Setup and sell as many products as you desire</div>
                </div>
                <div className="flex items-start justify-center order-1 pt-1 md:pt-0 md:items-center md:order-2"><FeatureSVG /></div>
            </div>
            <div className="flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-22 md:h-24">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Schedule product launches
                    <div className="text-sm opacity-70 text-left md:hidden">Prepare your campaign in advance and let it begin automatically</div>
                </div>
                <div className="flex items-start justify-center order-1 pt-1 md:pt-0 md:items-center md:order-2"><FeatureSVG /></div>
            </div>
            <div className="flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-20 md:h-24 bg-gray-100">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Geographical restrictions
                    <div className="text-sm opacity-70 text-left md:hidden">Limit which customers can place orders</div>
                </div>
                <div className="flex items-start justify-center order-1 pt-1 md:pt-0 md:items-center md:order-2"><FeatureSVG /></div>
            </div>
            <div className="flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-20 md:h-22">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Pre-orders
                    <div className="text-sm opacity-70 text-left md:hidden">Sell products now; fulfil later</div>
                </div>
                <div className="flex items-start justify-center order-1 pt-1 md:pt-0 md:items-center md:order-2"><FeatureSVG /></div>
            </div>
            <div className="hidden md:flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-22 md:h-24 bg-gray-100">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Multiple variants
                    <div className="text-sm opacity-70 text-left md:hidden">Create products with, for example, size <i>and</i> colour options</div>
                </div>
            </div>
            <div className="hidden md:flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-20 md:h-22">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Flexibile pricing setup
                    <div className="text-sm opacity-70 text-left md:hidden">Handle tax globally with ease</div>
                </div>
            </div>
            <div className="hidden md:flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-22 md:h-24 bg-gray-100">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Multi-currency
                    <div className="text-sm opacity-70 text-left md:hidden">Allow customers to purchase in their local currency</div>
                </div>
            </div>
            
            <div className="invisible py-1 md:py-0">space block</div>
            <div className="py-2 font-bold md:invisible">Campaigns</div>
            <div className="flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-22 md:h-24 bg-gray-100">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Bundles
                    <div className="text-sm opacity-70 text-left md:hidden">Sell more via bundling products together at a discounted price</div>
                </div>
                <div className="flex items-start justify-center order-1 pt-1 md:pt-0 md:items-center md:order-2"><FeatureSVG /></div>
            </div>
            <div className="flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-22 md:h-24">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Voucher codes
                    <div className="text-sm opacity-70 text-left md:hidden">Reward and incentivise your customers</div>
                </div>
                <div className="flex items-start justify-center order-1 pt-1 md:pt-0 md:items-center md:order-2"><FeatureSVG /></div>
            </div>
            <div className="hidden md:flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-22 md:h-24 bg-gray-100">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Fan clubs
                    <div className="text-sm opacity-70 text-left md:hidden">Build a community and generate a recurring revenue stream</div>
                </div>
            </div>
            <div className="hidden md:flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-22 md:h-24">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    YouTube Merch shelf
                    <div className="text-sm opacity-70 text-left md:hidden">Advertise your products on YouTube</div>
                </div>
            </div>
            <div className="hidden md:flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-22 md:h-24 bg-gray-100">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    UK chart reporting
                    <div className="text-sm opacity-70 text-left md:hidden">Your sales automatically reported to the Official Charts Company</div>
                </div>
            </div>
            <div className="hidden md:flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-22 md:h-24">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    International chart reporting
                    <div className="text-sm opacity-70 text-left md:hidden">Automated reporting to 10+ charts globally</div>
                </div>
            </div>

            <div className="invisible py-1 md:py-0">space block</div>
            <div className="flex py-2 font-bold md:invisible">General</div>
            <div className="flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-22 md:h-24 bg-gray-100">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Manage your orders
                    <div className="text-sm opacity-70 text-left md:hidden">Including fulfilment and customer support tools</div>
                </div>
                <div className="flex items-start justify-center order-1 pt-1 md:pt-0 md:items-center md:order-2"><FeatureSVG /></div>
            </div>
            <div className="flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-22 md:h-24">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Design your store
                    <div className="text-sm opacity-70 text-left md:hidden">A powerful yet simple store designer</div>
                </div>
                <div className="flex items-start justify-center order-1 pt-1 md:pt-0 md:items-center md:order-2"><FeatureSVG /></div>
            </div>
            <div className="flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-20 md:h-20 bg-gray-100">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Analytics dashboard
                    <div className="text-sm opacity-70 text-left md:hidden">Visualise your sales in real-time</div>
                </div>
                <div className="flex items-start justify-center order-1 pt-1 md:pt-0 md:items-center md:order-2"><FeatureSVG /></div>
            </div>
            <div className="flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-22 md:h-24">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Multiple users
                    <div className="text-sm opacity-70 text-left md:hidden">Allow your team to collaborate on your store</div>
                </div>
                <div className="flex items-start justify-center order-1 pt-1 md:pt-0 md:items-center md:order-2"><FeatureSVG /></div>
            </div>
            <div className="flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-22 md:h-24 bg-gray-100">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    User permissions
                    <div className="text-sm opacity-70 text-left md:hidden">Define exactly what collaborators are permitted to do</div>
                </div>
                <div className="flex items-start justify-center order-1 pt-1 md:pt-0 md:items-center md:order-2"><FeatureSVG /></div>
            </div>
            <div className="flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-22 md:h-24">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Custom domains
                    <div className="text-sm opacity-70 text-left md:hidden">Further promote your brand with a domain name for your store</div>
                </div>
                <div className="flex items-start justify-center order-1 pt-1 md:pt-0 md:items-center md:order-2"><FeatureSVG /></div>
            </div>
            <div className="hidden md:flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-22 md:h-24 bg-gray-100">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Multiple fulfillers
                    <div className="text-sm opacity-70 text-left md:hidden">Ship your orders from multiple warehouses</div>
                </div>
            </div>
            <div className="hidden md:flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-22 md:h-24">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Advanced SEO
                    <div className="text-sm opacity-70 text-left md:hidden">Customise data used for search engine optimisation</div>
                </div>
            </div>
            <div className="hidden md:flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-22 md:h-24 bg-gray-100">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Customer support
                    <div className="text-sm opacity-70 text-left md:hidden">Our dedicated customer service team look after your fans 24/7</div>
                </div>
            </div>
            <div className="hidden md:flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-22 md:h-24">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Custom store design & build
                    <div className="text-sm opacity-70 text-left md:hidden">Our in-house design team will build your storefront</div>
                </div>
            </div>
            <div className="hidden md:flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-22 md:h-24 bg-gray-100">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Account management
                    <div className="text-sm opacity-70 text-left md:hidden">Our account experts will manage your store</div>
                </div>
            </div>
            <div className="hidden md:flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-24 md:h-28">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Consultancy
                    <div className="text-sm opacity-70 text-left md:hidden">Our e-commerce consultants will help you get the most from your store</div>
                </div>
            </div>

            <div className="hidden md:flex md:invisible py-1 md:py-0">space block</div>
            <div className="hidden md:flex py-2 font-bold md:invisible">Integrations</div>
            <div className="hidden md:flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-22 md:h-24 bg-gray-100">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Global Fulfilment
                    <div className="text-sm opacity-70 text-left md:hidden">Integrate with warehouses worldwide</div>
                </div>
            </div>
            <div className="hidden md:flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-22 md:h-20">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Meta
                    <div className="text-sm opacity-70 text-left md:hidden">Facebook and Instagram shopping</div>
                </div>
            </div>
            <div className="hidden md:flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-22 md:h-24 bg-gray-100">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Klaviyo
                    <div className="text-sm opacity-70 text-left md:hidden">Marketing and e-commerce combined</div>
                </div>
            </div>
            <div className="hidden md:flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-22 md:h-20">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Zendesk
                    <div className="text-sm opacity-70 text-left md:hidden">Customer support at scale</div>
                </div>
            </div>
            <div className="hidden md:flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-22 md:h-24 bg-gray-100">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Google Products & Services
                    <div className="text-sm opacity-70 text-left md:hidden">Analytics, Tag Manager, Merchant Centre and more</div>
                </div>
            </div>
            <div className="hidden md:flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-22 md:h-24">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Mailchimp
                    <div className="text-sm opacity-70 text-left md:hidden">Sync your audience and utilise e-commerce marketing</div>
                </div>
            </div>
            <div className="hidden md:flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-r h-12 md:h-12 bg-gray-100">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    <i>Plus many more integrations...</i>
                </div>
            </div>
            <div className="flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none mt-4">
                <GetStarted className="py-2 px-4 text-base md:text-xs lg:text-base w-full hover:bg-mg-yellow hover:text-mg-purple"/>
            </div>
        </section>

         {/* Advanced Plan */}
         <section className="flex flex-col md:flex-1 mb-16 md:mb-0">
            <div className="text-lg lg:text-xl h-8 font-bold md:text-center">Advanced</div>
            <div className="py-2 font-bold md:invisible">Products</div>
            <div className="flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-22 md:h-24 bg-gray-100">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Unlimited products
                    <div className="text-sm opacity-70 text-left md:hidden">Setup and sell as many products as you desire</div>
                </div>
                <div className="flex items-start justify-center order-1 pt-1 md:pt-0 md:items-center md:order-2"><FeatureSVG /></div>
            </div>
            <div className="flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-22 md:h-24">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Schedule product launches
                    <div className="text-sm opacity-70 text-left md:hidden">Prepare your campaign in advance and let it begin automatically</div>
                </div>
                <div className="flex items-start justify-center order-1 pt-1 md:pt-0 md:items-center md:order-2"><FeatureSVG /></div>
            </div>
            <div className="flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-20 md:h-24 bg-gray-100">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Geographical restrictions
                    <div className="text-sm opacity-70 text-left md:hidden">Limit which customers can place orders</div>
                </div>
                <div className="flex items-start justify-center order-1 pt-1 md:pt-0 md:items-center md:order-2"><FeatureSVG /></div>
            </div>
            <div className="flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-20 md:h-22">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Pre-orders
                    <div className="text-sm opacity-70 text-left md:hidden">Sell products now; fulfil later</div>
                </div>
                <div className="flex items-start justify-center order-1 pt-1 md:pt-0 md:items-center md:order-2"><FeatureSVG /></div>
            </div>
            <div className="flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-22 md:h-24 bg-gray-100">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Multiple variants
                    <div className="text-sm opacity-70 text-left md:hidden">Create products with, for example, size <i>and</i> colour options</div>
                </div>
                <div className="flex items-start justify-center order-1 pt-1 md:pt-0 md:items-center md:order-2"><FeatureSVG /></div>
            </div>
            <div className="flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-20 md:h-22">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Flexibile pricing setup
                    <div className="text-sm opacity-70 text-left md:hidden">Handle tax globally with ease</div>
                </div>
                <div className="flex items-start justify-center order-1 pt-1 md:pt-0 md:items-center md:order-2"><FeatureSVG /></div>
            </div>
            <div className="flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-22 md:h-24 bg-gray-100">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Multi-currency
                    <div className="text-sm opacity-70 text-left md:hidden">Allow customers to purchase in their local currency</div>
                </div>
                <div className="flex items-start justify-center order-1 pt-1 md:pt-0 md:items-center md:order-2"><FeatureSVG /></div>
            </div>
            
            <div className="invisible py-1 md:py-0">space block</div>
            <div className="py-2 font-bold md:invisible">Campaigns</div>
            <div className="flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-22 md:h-24 bg-gray-100">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Bundles
                    <div className="text-sm opacity-70 text-left md:hidden">Sell more via bundling products together at a discounted price</div>
                </div>
                <div className="flex items-start justify-center order-1 pt-1 md:pt-0 md:items-center md:order-2"><FeatureSVG /></div>
            </div>
            <div className="flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-22 md:h-24">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Voucher codes
                    <div className="text-sm opacity-70 text-left md:hidden">Reward and incentivise your customers</div>
                </div>
                <div className="flex items-start justify-center order-1 pt-1 md:pt-0 md:items-center md:order-2"><FeatureSVG /></div>
            </div>
            <div className="flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-22 md:h-24 bg-gray-100">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Fan clubs
                    <div className="text-sm opacity-70 text-left md:hidden">Build a community and generate a recurring revenue stream</div>
                </div>
                <div className="flex items-start justify-center order-1 pt-1 md:pt-0 md:items-center md:order-2"><FeatureSVG /></div>
            </div>
            <div className="flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-22 md:h-24">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    YouTube Merch shelf
                    <div className="text-sm opacity-70 text-left md:hidden">Advertise your products on YouTube</div>
                </div>
                <div className="flex items-start justify-center order-1 pt-1 md:pt-0 md:items-center md:order-2"><FeatureSVG /></div>
            </div>
            <div className="flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-22 md:h-24 bg-gray-100">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    UK chart reporting
                    <div className="text-sm opacity-70 text-left md:hidden">Your sales automatically reported to the Official Charts Company</div>
                </div>
                <div className="flex items-start justify-center order-1 pt-1 md:pt-0 md:items-center md:order-2"><FeatureSVG /></div>
            </div>
            <div className="hidden md:flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-22 md:h-24">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    International chart reporting
                    <div className="text-sm opacity-70 text-left md:hidden">Automated reporting to 10+ charts globally</div>
                </div>
            </div>

            <div className="invisible py-1 md:py-0">space block</div>
            <div className="flex py-2 font-bold md:invisible">General</div>
            <div className="flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-22 md:h-24 bg-gray-100">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Manage your orders
                    <div className="text-sm opacity-70 text-left md:hidden">Including fulfilment and customer support tools</div>
                </div>
                <div className="flex items-start justify-center order-1 pt-1 md:pt-0 md:items-center md:order-2"><FeatureSVG /></div>
            </div>
            <div className="flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-22 md:h-24">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Design your store
                    <div className="text-sm opacity-70 text-left md:hidden">A powerful yet simple store designer</div>
                </div>
                <div className="flex items-start justify-center order-1 pt-1 md:pt-0 md:items-center md:order-2"><FeatureSVG /></div>
            </div>
            <div className="flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-20 md:h-20 bg-gray-100">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Analytics dashboard
                    <div className="text-sm opacity-70 text-left md:hidden">Visualise your sales in real-time</div>
                </div>
                <div className="flex items-start justify-center order-1 pt-1 md:pt-0 md:items-center md:order-2"><FeatureSVG /></div>
            </div>
            <div className="flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-22 md:h-24">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Multiple users
                    <div className="text-sm opacity-70 text-left md:hidden">Allow your team to collaborate on your store</div>
                </div>
                <div className="flex items-start justify-center order-1 pt-1 md:pt-0 md:items-center md:order-2"><FeatureSVG /></div>
            </div>
            <div className="flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-22 md:h-24 bg-gray-100">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    User permissions
                    <div className="text-sm opacity-70 text-left md:hidden">Define exactly what collaborators are permitted to do</div>
                </div>
                <div className="flex items-start justify-center order-1 pt-1 md:pt-0 md:items-center md:order-2"><FeatureSVG /></div>
            </div>
            <div className="flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-22 md:h-24">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Custom domains
                    <div className="text-sm opacity-70 text-left md:hidden">Further promote your brand with a domain name for your store</div>
                </div>
                <div className="flex items-start justify-center order-1 pt-1 md:pt-0 md:items-center md:order-2"><FeatureSVG /></div>
            </div>
            <div className="flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-22 md:h-24 bg-gray-100">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Multiple fulfillers
                    <div className="text-sm opacity-70 text-left md:hidden">Ship your orders from multiple warehouses</div>
                </div>
                <div className="flex items-start justify-center order-1 pt-1 md:pt-0 md:items-center md:order-2"><FeatureSVG /></div>
            </div>
            <div className="flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-22 md:h-24">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Advanced SEO
                    <div className="text-sm opacity-70 text-left md:hidden">Customise data used for search engine optimisation</div>
                </div>
                <div className="flex items-start justify-center order-1 pt-1 md:pt-0 md:items-center md:order-2"><FeatureSVG /></div>
            </div>
            <div className="hidden md:flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-22 md:h-24 bg-gray-100">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Customer support
                    <div className="text-sm opacity-70 text-left md:hidden">Our dedicated customer service team look after your fans 24/7</div>
                </div>
            </div>
            <div className="hidden md:flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-22 md:h-24">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Custom store design & build
                    <div className="text-sm opacity-70 text-left md:hidden">Our in-house design team will build your storefront</div>
                </div>
            </div>
            <div className="hidden md:flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-22 md:h-24 bg-gray-100">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Account management
                    <div className="text-sm opacity-70 text-left md:hidden">Our account experts will manage your store</div>
                </div>
            </div>
            <div className="hidden md:flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-24 md:h-28">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Consultancy
                    <div className="text-sm opacity-70 text-left md:hidden">Our e-commerce consultants will help you get the most from your store</div>
                </div>
            </div>

            <div className="invisible py-1 md:py-0">space block</div>
            <div className="flex py-2 font-bold md:invisible">Integrations</div>
            <div className="flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-22 md:h-24 bg-gray-100">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Global Fulfilment
                    <div className="text-sm opacity-70 text-left md:hidden">Integrate with warehouses worldwide</div>
                </div>
                <div className="flex items-start justify-center order-1 pt-1 md:pt-0 md:items-center md:order-2"><FeatureSVG /></div>
            </div>
            <div className="flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-22 md:h-20">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Meta
                    <div className="text-sm opacity-70 text-left md:hidden">Facebook and Instagram shopping</div>
                </div>
                <div className="flex items-start justify-center order-1 pt-1 md:pt-0 md:items-center md:order-2"><FeatureSVG /></div>
            </div>
            <div className="flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-22 md:h-24 bg-gray-100">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Klaviyo
                    <div className="text-sm opacity-70 text-left md:hidden">Marketing and e-commerce combined</div>
                </div>
                <div className="flex items-start justify-center order-1 pt-1 md:pt-0 md:items-center md:order-2"><FeatureSVG /></div>
            </div>
            <div className="flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-22 md:h-20">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Zendesk
                    <div className="text-sm opacity-70 text-left md:hidden">Customer support at scale</div>
                </div>
                <div className="flex items-start justify-center order-1 pt-1 md:pt-0 md:items-center md:order-2"><FeatureSVG /></div>
            </div>
            <div className="flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-22 md:h-24 bg-gray-100">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Google Products & Services
                    <div className="text-sm opacity-70 text-left md:hidden">Analytics, Tag Manager, Merchant Centre and more</div>
                </div>
                <div className="flex items-start justify-center order-1 pt-1 md:pt-0 md:items-center md:order-2"><FeatureSVG /></div>
            </div>
            <div className="flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none h-22 md:h-24">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Mailchimp
                    <div className="text-sm opacity-70 text-left md:hidden">Sync your audience and utilise e-commerce marketing</div>
                </div>
                <div className="flex items-start justify-center order-1 pt-1 md:pt-0 md:items-center md:order-2"><FeatureSVG /></div>
            </div>
            <div className="flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-r h-12 md:h-12 bg-gray-100">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    <i>Plus many more integrations...</i>
                </div>
                <div className="flex items-start justify-center order-1 pt-1 md:pt-0 md:items-center md:order-2"><FeatureSVG /></div>
            </div>
            <div className="flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-none mt-4">
                <GetStarted className="py-2 px-4 text-base md:text-xs lg:text-base w-full hover:bg-mg-yellow hover:text-mg-purple"/>
            </div>
        </section>

         {/* Managed Plan */}
         <section className="flex flex-col md:flex-1 mb-16 md:mb-0">
            <div className="text-lg lg:text-xl h-8 font-bold md:text-center">Managed</div>
            <div className="py-2 font-bold md:invisible">Products</div>
            <div className="flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-r h-22 md:h-24 bg-gray-100">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Unlimited products
                    <div className="text-sm opacity-70 text-left md:hidden">Setup and sell as many products as you desire</div>
                </div>
                <div className="flex items-start justify-center order-1 pt-1 md:pt-0 md:items-center md:order-2"><FeatureSVG /></div>
            </div>
            <div className="flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-r h-22 md:h-24">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Schedule product launches
                    <div className="text-sm opacity-70 text-left md:hidden">Prepare your campaign in advance and let it begin automatically</div>
                </div>
                <div className="flex items-start justify-center order-1 pt-1 md:pt-0 md:items-center md:order-2"><FeatureSVG /></div>
            </div>
            <div className="flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-r h-20 md:h-24 bg-gray-100">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Geographical restrictions
                    <div className="text-sm opacity-70 text-left md:hidden">Limit which customers can place orders</div>
                </div>
                <div className="flex items-start justify-center order-1 pt-1 md:pt-0 md:items-center md:order-2"><FeatureSVG /></div>
            </div>
            <div className="flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-r h-20 md:h-22">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Pre-orders
                    <div className="text-sm opacity-70 text-left md:hidden">Sell products now; fulfil later</div>
                </div>
                <div className="flex items-start justify-center order-1 pt-1 md:pt-0 md:items-center md:order-2"><FeatureSVG /></div>
            </div>
            <div className="flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-r h-22 md:h-24 bg-gray-100">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Multiple variants
                    <div className="text-sm opacity-70 text-left md:hidden">Create products with, for example, size <i>and</i> colour options</div>
                </div>
                <div className="flex items-start justify-center order-1 pt-1 md:pt-0 md:items-center md:order-2"><FeatureSVG /></div>
            </div>
            <div className="flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-r h-20 md:h-22">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Flexibile pricing setup
                    <div className="text-sm opacity-70 text-left md:hidden">Handle tax globally with ease</div>
                </div>
                <div className="flex items-start justify-center order-1 pt-1 md:pt-0 md:items-center md:order-2"><FeatureSVG /></div>
            </div>
            <div className="flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-r h-22 md:h-24 bg-gray-100">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Multi-currency
                    <div className="text-sm opacity-70 text-left md:hidden">Allow customers to purchase in their local currency</div>
                </div>
                <div className="flex items-start justify-center order-1 pt-1 md:pt-0 md:items-center md:order-2"><FeatureSVG /></div>
            </div>
            
            <div className="invisible py-1 md:py-0">space block</div>
            <div className="py-2 font-bold md:invisible">Campaigns</div>
            <div className="flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-r h-22 md:h-24 bg-gray-100">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Bundles
                    <div className="text-sm opacity-70 text-left md:hidden">Sell more via bundling products together at a discounted price</div>
                </div>
                <div className="flex items-start justify-center order-1 pt-1 md:pt-0 md:items-center md:order-2"><FeatureSVG /></div>
            </div>
            <div className="flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-r h-22 md:h-24">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Voucher codes
                    <div className="text-sm opacity-70 text-left md:hidden">Reward and incentivise your customers</div>
                </div>
                <div className="flex items-start justify-center order-1 pt-1 md:pt-0 md:items-center md:order-2"><FeatureSVG /></div>
            </div>
            <div className="flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-r h-22 md:h-24 bg-gray-100">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Fan clubs
                    <div className="text-sm opacity-70 text-left md:hidden">Build a community and generate a recurring revenue stream</div>
                </div>
                <div className="flex items-start justify-center order-1 pt-1 md:pt-0 md:items-center md:order-2"><FeatureSVG /></div>
            </div>
            <div className="flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-r h-22 md:h-24">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    YouTube Merch shelf
                    <div className="text-sm opacity-70 text-left md:hidden">Advertise your products on YouTube</div>
                </div>
                <div className="flex items-start justify-center order-1 pt-1 md:pt-0 md:items-center md:order-2"><FeatureSVG /></div>
            </div>
            <div className="flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-r h-22 md:h-24 bg-gray-100">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    UK chart reporting
                    <div className="text-sm opacity-70 text-left md:hidden">Your sales automatically reported to the Official Charts Company</div>
                </div>
                <div className="flex items-start justify-center order-1 pt-1 md:pt-0 md:items-center md:order-2"><FeatureSVG /></div>
            </div>
            <div className="flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-r h-22 md:h-24">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    International chart reporting
                    <div className="text-sm opacity-70 text-left md:hidden">Automated reporting to 10+ charts globally</div>
                </div>
                <div className="flex items-start justify-center order-1 pt-1 md:pt-0 md:items-center md:order-2"><FeatureSVG /></div>
            </div>

            <div className="invisible py-1 md:py-0">space block</div>
            <div className="flex py-2 font-bold md:invisible">General</div>
            <div className="flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-r h-22 md:h-24 bg-gray-100">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Manage your orders
                    <div className="text-sm opacity-70 text-left md:hidden">Including fulfilment and customer support tools</div>
                </div>
                <div className="flex items-start justify-center order-1 pt-1 md:pt-0 md:items-center md:order-2"><FeatureSVG /></div>
            </div>
            <div className="flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-r h-22 md:h-24">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Design your store
                    <div className="text-sm opacity-70 text-left md:hidden">A powerful yet simple store designer</div>
                </div>
                <div className="flex items-start justify-center order-1 pt-1 md:pt-0 md:items-center md:order-2"><FeatureSVG /></div>
            </div>
            <div className="flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-r h-20 md:h-20 bg-gray-100">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Analytics dashboard
                    <div className="text-sm opacity-70 text-left md:hidden">Visualise your sales in real-time</div>
                </div>
                <div className="flex items-start justify-center order-1 pt-1 md:pt-0 md:items-center md:order-2"><FeatureSVG /></div>
            </div>
            <div className="flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-r h-22 md:h-24">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Multiple users
                    <div className="text-sm opacity-70 text-left md:hidden">Allow your team to collaborate on your store</div>
                </div>
                <div className="flex items-start justify-center order-1 pt-1 md:pt-0 md:items-center md:order-2"><FeatureSVG /></div>
            </div>
            <div className="flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-r h-22 md:h-24 bg-gray-100">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    User permissions
                    <div className="text-sm opacity-70 text-left md:hidden">Define exactly what collaborators are permitted to do</div>
                </div>
                <div className="flex items-start justify-center order-1 pt-1 md:pt-0 md:items-center md:order-2"><FeatureSVG /></div>
            </div>
            <div className="flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-r h-22 md:h-24">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Custom domains
                    <div className="text-sm opacity-70 text-left md:hidden">Further promote your brand with a domain name for your store</div>
                </div>
                <div className="flex items-start justify-center order-1 pt-1 md:pt-0 md:items-center md:order-2"><FeatureSVG /></div>
            </div>
            <div className="flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-r h-22 md:h-24 bg-gray-100">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Multiple fulfillers
                    <div className="text-sm opacity-70 text-left md:hidden">Ship your orders from multiple warehouses</div>
                </div>
                <div className="flex items-start justify-center order-1 pt-1 md:pt-0 md:items-center md:order-2"><FeatureSVG /></div>
            </div>
            <div className="flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-r h-22 md:h-24">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Advanced SEO
                    <div className="text-sm opacity-70 text-left md:hidden">Customise data used for search engine optimisation</div>
                </div>
                <div className="flex items-start justify-center order-1 pt-1 md:pt-0 md:items-center md:order-2"><FeatureSVG /></div>
            </div>
            <div className="flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-r h-22 md:h-24 bg-gray-100">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Customer support
                    <div className="text-sm opacity-70 text-left md:hidden">Our dedicated customer service team look after your fans 24/7</div>
                </div>
                <div className="flex items-start justify-center order-1 pt-1 md:pt-0 md:items-center md:order-2"><FeatureSVG /></div>
            </div>
            <div className="flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-r h-22 md:h-24">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Custom store design & build
                    <div className="text-sm opacity-70 text-left md:hidden">Our in-house design team will build your storefront</div>
                </div>
                <div className="flex items-start justify-center order-1 pt-1 md:pt-0 md:items-center md:order-2"><FeatureSVG /></div>
            </div>
            <div className="flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-r h-22 md:h-24 bg-gray-100">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Account management
                    <div className="text-sm opacity-70 text-left md:hidden">Our account experts will manage your store</div>
                </div>
                <div className="flex items-start justify-center order-1 pt-1 md:pt-0 md:items-center md:order-2"><FeatureSVG /></div>
            </div>
            <div className="flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-r h-24 md:h-28">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Consultancy
                    <div className="text-sm opacity-70 text-left md:hidden">Our e-commerce consultants will help you get the most from your store</div>
                </div>
                <div className="flex items-start justify-center order-1 pt-1 md:pt-0 md:items-center md:order-2"><FeatureSVG /></div>
            </div>

            <div className="invisible py-1 md:py-0">space block</div>
            <div className="flex py-2 font-bold md:invisible">Integrations</div>
            <div className="flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-r h-22 md:h-24 bg-gray-100">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Global Fulfilment
                    <div className="text-sm opacity-70 text-left md:hidden">Integrate with warehouses worldwide</div>
                </div>
                <div className="flex items-start justify-center order-1 pt-1 md:pt-0 md:items-center md:order-2"><FeatureSVG /></div>
            </div>
            <div className="flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-r h-22 md:h-20">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Meta
                    <div className="text-sm opacity-70 text-left md:hidden">Facebook and Instagram shopping</div>
                </div>
                <div className="flex items-start justify-center order-1 pt-1 md:pt-0 md:items-center md:order-2"><FeatureSVG /></div>
            </div>
            <div className="flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-r h-22 md:h-24 bg-gray-100">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Klaviyo
                    <div className="text-sm opacity-70 text-left md:hidden">Marketing and e-commerce combined</div>
                </div>
                <div className="flex items-start justify-center order-1 pt-1 md:pt-0 md:items-center md:order-2"><FeatureSVG /></div>
            </div>
            <div className="flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-r h-22 md:h-20">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Zendesk
                    <div className="text-sm opacity-70 text-left md:hidden">Customer support at scale</div>
                </div>
                <div className="flex items-start justify-center order-1 pt-1 md:pt-0 md:items-center md:order-2"><FeatureSVG /></div>
            </div>
            <div className="flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-r h-22 md:h-24 bg-gray-100">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Google Products & Services
                    <div className="text-sm opacity-70 text-left md:hidden">Analytics, Tag Manager, Merchant Centre and more</div>
                </div>
                <div className="flex items-start justify-center order-1 pt-1 md:pt-0 md:items-center md:order-2"><FeatureSVG /></div>
            </div>
            <div className="flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-r h-22 md:h-24">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    Mailchimp
                    <div className="text-sm opacity-70 text-left md:hidden">Sync your audience and utilise e-commerce marketing</div>
                </div>
                <div className="flex items-start justify-center order-1 pt-1 md:pt-0 md:items-center md:order-2"><FeatureSVG /></div>
            </div>
            <div className="flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-r h-12 md:h-12 bg-gray-100">
                <div className="flex flex-col gap-2 justify-start order-2 md:order-1 md:hidden">
                    <i>Plus many more integrations...</i>
                </div>
                <div className="flex items-start justify-center order-1 pt-1 md:pt-0 md:items-center md:order-2"><FeatureSVG /></div>
            </div>
            <div className="flex w-full justify-start gap-4 rounded p-3 text-base md:justify-center md:rounded-r mt-4">
                <GetInTouch className="py-2 px-4 text-base md:text-xs lg:text-base w-full hover:bg-mg-yellow hover:text-mg-purple"/>
            </div>
        </section>
    </div>

  )
}

export default ComparisonTable
